import React, { useReducer } from "react";
import axios from "axios";
import { search } from "../../services/api.search";
import { LoginHomeContext } from "./loginHomeContext";
import { loginHomeReducer } from "./loginHomeReducer";
import {
  SHOW_ALERT,
  HIDE_ALERT,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  SET_CREDENTIALS,
  SET_SEARCHPOSSION,
  SET_LEADSSUMMARY,
  SET_ANALYTICSRES,
  SET_PROPERTY,
} from "../types";


let encryptor = require("simple-encryptor")(process.env.REACT_APP_SICRET);

export const LoginHomeState = ({ children }) => {
  const initialState = {
    loading: true,
    alertMessage: "",
    token: null,
    credentials: [],
    searchPossition: null,
    leadsSummary: [],
    analyticsRes: null,
    property: 0,
  };



  const [state, dispatch] = useReducer(loginHomeReducer, initialState);

  const siteUrl = process.env.REACT_APP_SITE_URL;

  const autoLogin = () => {
    const token = localStorage.getItem("mama-app-token");
    const expiryDay = localStorage.getItem("mama-app-expiry-day");
    if (token && new Date(expiryDay) >= new Date()) {
      authSuccess(token);
    }
  };

  const getToken = async (formData) => {


		if (typeof localStorage !== 'undefined') {
			try {
					localStorage.setItem('feature_test', 'yes');
					if (localStorage.getItem('feature_test') === 'yes') {
							localStorage.removeItem('feature_test');
							alert("localStorage is enabled")
					} else {
						alert("localStorage is disabled")
					}
			} catch(e) {
				alert(`localStorage is disabled 
				${e}`)
	
			}
	} else {
		alert("localStorage is not available")
	
	}

    let token = null;
    const expiresIn = 1;

    try {
      const res = await axios.post(
        `${siteUrl}/wp-json/jwt-auth/v1/token`,
        formData
      );

			alert(res.data.token)

      if (undefined === res.data.token) {
        alert("token", res.data.token);
				alert(`token is underfined ${res}`);
        return;
      }

      token = res.data.token;

      if (token) {
        const expiryDay = new Date(
          new Date().getTime() +
            expiresIn * 3600 * 24 * 1000 * process.env.REACT_APP_EXPIRY_TERM
        );
        localStorage.setItem("mama-app-token", token);
        localStorage.setItem("mama-app-expiry-day", expiryDay);
        authSuccess(token);
      }
    } catch (err) {
      console.log("caught error", err);
			alert(`token is underfined ${err}`);
			alert('Error data : ', err.response.data);
			alert('Error status : ', err.response.status);
			alert('Error headers : ', err.response.headers);
			alert('Error request : ', err.request);			
			alert('Error message', err.message);				

      // showAlert("Login or password is not correct");
    }
    return token;
  };

  const getCredentials = async (token) => {
    let credentials = null;

    credentials = localStorage.getItem("mama-app-credentials");

    if (credentials) {
      credentials = JSON.parse(encryptor.decrypt(credentials));
      // credentials = JSON.parse(credentials)
      setCredentials(credentials);
      return credentials;
    } else {
      try {
        const res = await axios.post(
          `${siteUrl}/wp-json/jwt-auth/app-credentials`,
          null,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        credentials = res.data;
        credentials.forEach((credential) => {
          credential["SEARCHWORDS"] = credential["SEARCHWORDS"].split(",");
        });

        localStorage.setItem(
          "mama-app-credentials",
          encryptor.encrypt(JSON.stringify(credentials))
        );
        setCredentials(credentials);
        return credentials;
      } catch (e) {
        console.log(e);
        logout();
        showAlert("Please log out and try again");
        return null;
      }
    }
  };

  const getSerachPossition = async (
    SEARCHWORDS,
    WEBSITEURL,
    SEARCHKEY,
    SEARCHID
  ) => {
    const DOMAINNAME = new URL(WEBSITEURL).hostname.replace("www.", "");

    const searchPossition = await search(
      SEARCHWORDS,
      DOMAINNAME,
      SEARCHKEY,
      SEARCHID
    );

    setSearchPossition(searchPossition);

    return searchPossition;
  };

  const getLeads = async (WEBSITEURL, WEBSITEAPPPASSWORD, property) => {
    try {
      const res = await axios.post(
        `${WEBSITEURL}/wp-json/leads-report/summary`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${WEBSITEAPPPASSWORD}`,
          },
        }
      );

      if (res.data.status === 403) {
        showAlert(
          "Please log in and try again. If the problem persist, please, contact MAMA WEB Advertising"
        );
        logout();
      }

      const leadSummeryLocal = leadsSummary;

      leadSummeryLocal[property] = res.data;

      setLeadsSummary(leadSummeryLocal);

      return leadSummeryLocal;
    } catch (e) {
      console.log(e);
      showAlert(
        "Please log in and try again. If the problem persist, please, contact MAMA WEB Advertising"
      );
    }
  };

  const getAnalytics = async (ANALYTICSVIEWID) => {
    try {
      const res = await axios.post(
        `${siteUrl}/wp-json/jwt-auth/analytics`,
        { ANALYTICSVIEWID: ANALYTICSVIEWID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data.status === 403) {
        logout();
        showAlert(
          "Please log in and try again. If the problem persist, please, contact MAMA WEB Advertising"
        );
      }

      setAnalyticsRes(res.data);

      return res.data;
    } catch (e) {
      console.log(e);
      showAlert(
        "Please log in and try again. If the problem persist, please, contact MAMA WEB Advertising"
      );
    }
  };

  const startSummary = async (credentials, property) => {
    const {
      SEARCHWORDS,
      SEARCHKEY,
      SEARCHID,
      ANALYTICSVIEWID,
      WEBSITEURL,
      WEBSITEAPPPASSWORD,
    } = credentials;

    // console.log(SEARCHWORDS,
    //   SEARCHKEY,
    //   SEARCHID,
    //   ANALYTICSVIEWID,
    //   WEBSITEURL,
    //   WEBSITEAPPPASSWORD)

    Promise.all([
      getSerachPossition(SEARCHWORDS, WEBSITEURL, SEARCHKEY, SEARCHID),
      getLeads(WEBSITEURL, WEBSITEAPPPASSWORD, property),
      getAnalytics(ANALYTICSVIEWID),
    ])
      .then(() => {})
      .catch(console.log);
  };

  const authSuccess = (token) =>
    dispatch({
      type: AUTH_SUCCESS,
      payload: token,
    });

  const logout = () => {
    localStorage.removeItem("mama-app-token");
    localStorage.removeItem("mama-app-expiry-day");
    localStorage.removeItem("mama-app-credentials");
    dispatch({ type: AUTH_LOGOUT });
  };

  const setCredentials = (credentials) =>
    dispatch({
      type: SET_CREDENTIALS,
      payload: credentials,
    });

  const setSearchPossition = (searchPossition) =>
    dispatch({
      type: SET_SEARCHPOSSION,
      payload: searchPossition,
    });

  const setLeadsSummary = (leadsSummary) =>
    dispatch({
      type: SET_LEADSSUMMARY,
      payload: leadsSummary,
    });

  const setAnalyticsRes = (analyticsrRes) =>
    dispatch({
      type: SET_ANALYTICSRES,
      payload: analyticsrRes,
    });

  const setProperty = (property) =>
    dispatch({
      type: SET_PROPERTY,
      payload: property,
    });

  const showAlert = (alertMessage) => {
    dispatch({
      type: SHOW_ALERT,
      payload: alertMessage,
    });
    setTimeout(() => {
      hideAlert();
    }, 4000);
  };

  const hideAlert = () =>
    dispatch({
      type: HIDE_ALERT,
    });

  const {
    loading,
    alertMessage,
    token,
    credentials,
    searchPossition,
    leadsSummary,
    analyticsRes,
    property,
  } = state;

  return (
    <LoginHomeContext.Provider
      value={{
        autoLogin,
        logout,
        getToken,
        getCredentials,
        startSummary,
        setCredentials,
        setSearchPossition,
        setLeadsSummary,
        setAnalyticsRes,
        setProperty,
        loading,
        token,
        credentials,
        searchPossition,
        leadsSummary,
        getLeads,
        analyticsRes,
        alertMessage,
        property,
      }}
    >
      {children}
    </LoginHomeContext.Provider>
  );
};

// import React, { useReducer } from "react";
// import axios from "axios";
// import { search } from "../../services/api.search";
// import { LoginHomeContext } from "./loginHomeContext";
// import { loginHomeReducer } from "./loginHomeReducer";
// import {
//   SET_LOADING,
//   STOP_LOADING,
//   SHOW_ALERT,
//   HIDE_ALERT,
//   AUTH_SUCCESS,
//   AUTH_LOGOUT,
//   SET_CREDENTIALS,
//   SET_SEARCHPOSSION,
//   SET_LEADSSUMMARY,
//   SET_ANALYTICSRES,
// } from "../types";

// let encryptor = require("simple-encryptor")("mama-website-report-app-sectet");

// export const LoginHomeState = ({ children }) => {
//   const initialState = {
//     loading: false,
//     alertMessage: "",
//     token: null,
//     credentials: [],
//     searchPossition: null,
//     leadsSummary: null,
//     analyticsRes: null,
//   };

//   const [state, dispatch] = useReducer(loginHomeReducer, initialState);

//   const siteUrl = "https://mama.com.au";

//   const autoLogin = () => {
//     const token = localStorage.getItem("mama-app-token");
//     const expiryDay = localStorage.getItem("mama-app-expiry-day");
//     if (token && new Date(expiryDay) >= new Date()) {
//       authSuccess(token);
//     }
//   };

//   const getToken = async (formData) => {
//     let token = null;
//     const expiresIn = 1;

//     try {
//       const res = await axios.post(
//         `${siteUrl}/wp-json/jwt-auth/v1/token`,
//         formData
//       );

//       if (undefined === res.data.token) {
//         console.log("token", res.data.token);
//         return;
//       }

//       token = res.data.token;

//       if (token) {
//         const expiryDay = new Date(
//           new Date().getTime() + expiresIn * 3600 * 24 * 1000
//         );
//         localStorage.setItem("mama-app-token", token);
//         localStorage.setItem("mama-app-expiry-day", expiryDay);
//         authSuccess(token);
//       }
//     } catch (err) {
//       console.log("caught error", err);
//       showAlert("Login or password is not correct");
//     }
//     return token;
//   };

//   const getCredentials = async (token) => {
//     let credentials = null;

//     credentials = localStorage.getItem("mama-app-credentials");

//     if (credentials) {
//       credentials = JSON.parse(encryptor.decrypt(credentials));
//       // credentials = JSON.parse(credentials)
//       setCredentials(credentials);
//       return credentials;
//     } else {
//       try {
//         const res = await axios.post(
//           `${siteUrl}/wp-json/jwt-auth/app-credentials`,
//           null,
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         credentials = res.data;
//         credentials.forEach((credential) => {
//           credential["SEARCHWORDS"] = credential["SEARCHWORDS"].split(",");
//         });

//         localStorage.setItem(
//           "mama-app-credentials",
//           encryptor.encrypt(JSON.stringify(credentials))
//         );
//         // localStorage.setItem( 'mama-app-credentials',  JSON.stringify(credentials));
//         setCredentials(credentials);
//         return credentials;
//       } catch (e) {
//         console.log(e);
//         logout();
//         showAlert("Please log in and try again");
//         return null;
//       }
//     }
//   };

//   const startSummary = async (credentials) => {
//     const {
//       SEARCHWORDS,
//       SEARCHKEY,
//       SEARCHID,
//       ANALYTICSVIEWID,
//       WEBSITEURL,
//       WEBSITEAPPPASSWORD,
//     } = credentials;

//     setLoading();

//     // console.log(SEARCHWORDS,
//     //   SEARCHKEY,
//     //   SEARCHID,
//     //   ANALYTICSVIEWID,
//     //   WEBSITEURL,
//     //   WEBSITEAPPPASSWORD)

//     const getSerachPossition = async (
//       SEARCHWORDS,
//       WEBSITEURL,
//       SEARCHKEY,
//       SEARCHID
//     ) => {
//       const DOMAINNAME = new URL(WEBSITEURL).hostname.replace("www.", "");

//       const searchPossition = await search(
//         SEARCHWORDS,
//         DOMAINNAME,
//         SEARCHKEY,
//         SEARCHID
//       );

//       setSearchPossition(searchPossition);

//       return searchPossition;
//     };

//     const getLeads = async (WEBSITEURL, WEBSITEAPPPASSWORD) => {
//       try {
//         const res = await axios.post(
//           `${WEBSITEURL}/wp-json/leads-report/summary`,
//           null,
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Basic ${WEBSITEAPPPASSWORD}`,
//             },
//           }
//         );

//         if (res.data.status === 403) {
//           showAlert(
//             "Please log in and try again. If the problem persist, please, contact MAMA WEB Advertising"
//           );
//           logout();
//         }

//         setLeadsSummary(res.data);

//         return res.data;
//       } catch (e) {
//         console.log(e);
//         showAlert(
//           "Please log in and try again. If the problem persist, please, contact MAMA WEB Advertising"
//         );
//       }
//     };

//     const getAnalytics = async (ANALYTICSVIEWID) => {
//       try {
//         const res = await axios.post(
//           `${siteUrl}/wp-json/jwt-auth/analytics`,
//           { ANALYTICSVIEWID: ANALYTICSVIEWID },
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (res.data.status === 403) {
//           logout();
//           showAlert(
//             "Please log in and try again. If the problem persist, please, contact MAMA WEB Advertising"
//           );
//         }

//         setAnalyticsRes(res.data);

//         return res.data;
//       } catch (e) {
//         console.log(e);
//         showAlert(
//           "Please log in and try again. If the problem persist, please, contact MAMA WEB Advertising"
//         );
//       }
//     };

//     Promise.all([
//       getSerachPossition(SEARCHWORDS, WEBSITEURL, SEARCHKEY, SEARCHID),
//       getLeads(WEBSITEURL, WEBSITEAPPPASSWORD),
//       getAnalytics(ANALYTICSVIEWID),
//     ])
//       .then(() => {
//         stopLoading();
//       })
//       .catch(console.log);
//   };

//   const setLoading = () => dispatch({ type: SET_LOADING });
//   const stopLoading = () => dispatch({ type: STOP_LOADING });
//   const authSuccess = (token) =>
//     dispatch({
//       type: AUTH_SUCCESS,
//       payload: token,
//     });

//   const logout = () => {
//     localStorage.removeItem("mama-app-token");
//     localStorage.removeItem("mama-app-expiry-day");
//     localStorage.removeItem("mama-app-credentials");
//     dispatch({ type: AUTH_LOGOUT });

//   };
//   const setCredentials = (credentials) =>
//     dispatch({
//       type: SET_CREDENTIALS,
//       payload: credentials,
//     });

//   const setSearchPossition = (searchPossition) =>
//     dispatch({
//       type: SET_SEARCHPOSSION,
//       payload: searchPossition,
//     });

//   const setLeadsSummary = (leadsSummary) =>
//     dispatch({
//       type: SET_LEADSSUMMARY,
//       payload: leadsSummary,
//     });

//   const setAnalyticsRes = (analyticsrRes) =>
//     dispatch({
//       type: SET_ANALYTICSRES,
//       payload: analyticsrRes,
//     });

//   const showAlert = (alertMessage) => {
//     dispatch({
//       type: SHOW_ALERT,
//       payload: alertMessage,
//     });
//     setTimeout(() => {
//       hideAlert();
//     }, 5000);
//   };

//   const hideAlert = () =>
//     dispatch({
//       type: HIDE_ALERT,
//     });

//   // export function logout() {
//   //   localStorage.removeItem('token')
//   //   localStorage.removeItem('userId')
//   //   localStorage.removeItem('expirationDate')
//   //   return {
//   //     type: AUTH_LOGOUT
//   //   }
//   // }

//   const {
//     loading,
//     alertMessage,
//     token,
//     credentials,
//     searchPossition,
//     leadsSummary,
//     analyticsRes,
//   } = state;

//   return (
//     <LoginHomeContext.Provider
//       value={{
//         setLoading,
//         autoLogin,
//         logout,
//         getToken,
//         getCredentials,
//         startSummary,
//         setCredentials,
//         loading,
//         token,
//         credentials,
//         searchPossition,
//         leadsSummary,
//         analyticsRes,
//         alertMessage,
//       }}
//     >
//       {children}
//     </LoginHomeContext.Provider>
//   );
// };
