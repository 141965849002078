import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Hamburger from "hamburger-react";
import "./Navbar.scss";
import { default as logo } from "../../img/app-mama-logo.svg";
import { Drawer } from "../Navigation/Drawer/Drawer";
import { Auxiliary } from "../../hoc/Auxilary/Auxiliary";
import { LoginHomeContext } from "../../context/loginHome/loginHomeContext";

export const Navbar = (props) => {
  const [isOpen, setOpen] = useState(false);
  const { credentials, property } = useContext(LoginHomeContext);
  let history = useHistory();

  let path = history.location.pathname;

  const cls = [];
  cls.push("navbar");

  switch (path) {
    case "/":
      break;
    case "/leads":
      cls.push("leads-page");
      break;
    case "/analytics":
      cls.push("analytics-page");
      break;
    default:
      break;
  }

  const fixBody = () => {
    if (document.body.classList.contains("fixed")) {
      document.body.classList.remove("fixed");
    } else {
      document.body.classList.add("fixed");
    }
  };

  const toggleMenuHandle = () => {
    fixBody();
    setOpen((isOpen) => !isOpen);
  };

  const menuCloseHandler = () => {
    setOpen(false);
  };

  return (
    <div className={cls.join(" ")}>
      <div className="container">
        <div className="navbar-wrap">
          {path === "/" ? (
            <Auxiliary>
              <Link to="/" className="link">
                Website Report
              </Link>

              <Link to="/" className="link">
                <img src={logo} className="mama-logo" alt="Mama logo" />
              </Link>

              <div className="toggle-btn">
                {credentials.length > 0 ? (
                  <Hamburger toggled={isOpen} toggle={toggleMenuHandle} />
                ) : (
                  ""
                )}
              </div>
            </Auxiliary>
          ) : (
            <Auxiliary>
              <div className="logo-wrap">
                <Link to="/" className="link">
                  <img src={logo} className="mama-logo" alt="Mama logo" />
                </Link>
                <Link to="/" className="link">
                  {credentials.length > 0
                    ? credentials[property]["PROPERTY"]
                    : "Website Report"}
                </Link>
              </div>

              <div className="toggle-btn">
                {credentials.length > 0 ? (
                  <Hamburger toggled={isOpen} toggle={toggleMenuHandle} />
                ) : (
                  ""
                )}
              </div>
            </Auxiliary>
          )}
        </div>
      </div>
      <Drawer isOpen={isOpen} onClose={menuCloseHandler} />
    </div>
  );
};
