import React from "react";
import "./CustomChart.scss";
import { Line } from "react-chartjs-2";

export const CustomChart = (props) => {
  const { customData, labels } = props;
  const data = {
    labels: labels,
    datasets: [
      {
        label: "",
        data: customData,
        fill: false,
        backgroundColor: "rgb(255, 99, 100)",
        borderColor: "rgba(255, 99, 132, 0.2)",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },

    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <div className="CustomChart">
      <Line data={data} options={options} />
    </div>
  );
};
