import React from 'react'
import './Button.scss'


export const Button = props => {

	const cls = ["Button"]

	if(props.classname) {
		cls.push(props.classname)
	}

	if(props.disabled) {
		cls.push("disabled")
	}
	
	return (
		<button 			
			className={cls.join(' ')}
			type={props.type}
			onClick={() => props.onClick(props.link)}      
			disabled={props.disabled}
		>{props.label}
		</button>	
	)
}

