import React from 'react'
import './Footer.scss'
import {Socials} from '../Socials/Socials'


export const Footer = () => {




  return (
    <div className='footer'>
      <div className="container">
        <div className="footer-wrap">
          <span>@{new Date().getFullYear()} MAMA Advertising Pty</span>
          <Socials/>
        </div>
      </div>
    </div>
  )
}
