import axios from 'axios'



export async function search(keywords, site, searchkey, searchid) {
    const enquiry =  keywords.join('+')
    let result = null;

    async function getSearch (enquiry, position = 1) {
        try {
            const response =  await axios.get(`https://www.googleapis.com/customsearch/v1?key=${searchkey}&cx=${searchid}&start=${position}&q=${enquiry}`)
            return response  
        }   catch(error) {
            console.log(error)
        }
    }    
    
    async function findItem(enquiry, possition = 1) {

        let respond = await getSearch(enquiry, possition,)
        let items = respond.data.items
        

        if(!items) return null

        for(let i = 0; i < items.length; i++ ) {
            if(!(items[i].link.match(new RegExp(site)) === null)) {
                result = possition + i
                break;
            }
        }

        if(result === null ) {
            possition = possition + 10
            if(possition < 92) { 
              await findItem(enquiry, possition) 
            }  
        } 

        return result
    }
    return await findItem(enquiry)
}