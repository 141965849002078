import React, { useReducer } from "react";
import axios from "axios";
import { AnalyticsContext } from "./analyticsContext";
import { AnalyticsReducer } from "./analyticsReducer";
import { SET_ANALYTICSDATA } from "../types";

export const AnalyticsState = ({ children }) => {
  const initialState = {
    analyticsData: [],
  };

  const siteUrl = "https://mama.com.au";

  const [state, dispatch] = useReducer(AnalyticsReducer, initialState);

  const getFullAnalytics = async (ANALYTICSVIEWID, token, property) => {
    const res = await axios.post(
      `${siteUrl}/wp-json/jwt-auth/analytics-report`,
      { ANALYTICSVIEWID: ANALYTICSVIEWID },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.data.status === 403) {
    } else if (res.data) {
      let analyticsDataLocal = analyticsData;
      analyticsDataLocal[property] = res.data;
      setAnalyticsData(analyticsDataLocal);
    }
  };

	const cleanAnalytics = () => {
		dispatch({
      type: SET_ANALYTICSDATA,
      payload: [],
    });
	}

  const setAnalyticsData = (analyticsData) =>
    dispatch({
      type: SET_ANALYTICSDATA,
      payload: analyticsData,
    });

  const { analyticsData } = state;

  return (
    <AnalyticsContext.Provider
      value={{
        getFullAnalytics,
				cleanAnalytics,
        analyticsData,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
