import React from 'react'
import './TabContent.scss'
 
export const TabContent = (props) => {



  const ths = Object.keys(props.form[0])
  .map((th, index) => {
      th = th.replace(/your-/i, '')
      .replace('-', ' ')
      .replace('howdidyouhearaboutus', 'How did you hear about us')
      .replace('lengthofrentalhistory', 'Length of rental history')
      .replace('firstname', 'First name')
      .replace('lastname', 'Last name')
      .replace('fullname', 'Full name')
      th = th.charAt(0).toUpperCase() + th.slice(1)
      return th
  })

  

  const cls = ["TabContent"]

  if(props.isActive) {
    cls.push('active')
  }

  return (
    <div  className={cls.join(' ')}>
      <table>
        <thead>
          <tr>
            {ths.map((th, index) => {
              return (
                <th key={'th' + index}>
                  {th}
                </th>
              )
            })}
          </tr>  
        </thead>
        <tbody>
          {props.form.map((lead, indexLead) => {
							const cls = indexLead < props.diff ? 'green' : ''
              return (
                <tr key={indexLead.toString(3)} className={cls}>
                {Object.keys(lead).map((column, indexColumn) => {

                  return (
                    <td key={indexLead.toString(3) + indexColumn }>

											{(column !== 'date') ? lead[column] : new Date(lead[column] * 1000).toLocaleDateString("en-AU")}
                    </td>
                  )
                })}
                </tr>
              )
            })}
        </tbody>
      </table>    
    </div>  
  )
}


