import React from 'react'
import './TabTags.scss'


export const TabTags = (props) => {



  const cls = ["TabTags"]

  if(props.isActive) {
    cls.push('active')
  }

  return (
    <div className={cls.join(' ')} onClick={() => {props.onClick(props.id)}}>{props.form} </div>
  )
}