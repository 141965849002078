import React, { useState } from "react";
import "./AnalyticsDynamic.scss";

import { CustomChart } from "../Chart/CustomChart/CustomChart";
import { ChartSwitcher } from "../Chart/ChartSwitcher/ChartSwitver";
import { Auxiliary } from "../../hoc/Auxilary/Auxiliary";

export const AnalyticsDynamic = (props) => {
  const [measure, setMeasure] = useState("weekly");

  const switcherHandler = () => {
    setMeasure((prevState) => {
      if (prevState === "weekly") return "monthly";
      if (prevState === "monthly") return "weekly";
    });
  };

  return (
    <Auxiliary>
      <CustomChart
        customData={props.analyticsData[measure]["data"]}
        labels={props.analyticsData[measure]["labels"]}
      />
      <ChartSwitcher onClickHandler={switcherHandler} measure={measure} />
    </Auxiliary>
  );
};
