import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { LoginHomeContext } from "../../context/loginHome/loginHomeContext";

export const Logout = (props) => {
  const { logout } = useContext(LoginHomeContext);
  const [mount, setMount] = useState(false);

  useEffect(() => {
    async function start() {
      logout();
    }

    if (!mount) {
      setMount(true);
      start();
    }
  }, [logout, mount]);

  // props.history.push("/");
  // window.location.href = "/login";

  return <Redirect to={"/"} />;
};
