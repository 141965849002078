import React from 'react'
import './SummaryItem.scss'
import {Button} from '../../UI/Button/Button'
 
export const SummaryItem = (props) => {
 return (
    <div className="SummaryItem">
      <h2 className="title">
        {props.title}
      </h2>
      <div className="data">
        {props.data}
      </div>
      <div className="comment">
        {props.comment}
      </div>
			{props.isButton ? <Button 
				label={props.buttonLabel}
				link={props.buttonLink}
				onClick={props.onClickButton}
			/> : ''}
			
    </div>
 )
}