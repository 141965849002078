import React from 'react'
import './Socials.scss'
import {default as facebookIcon} from '../../img/facebook-icon.svg';
import {default as instagramIcon} from '../../img/instagram-icon.svg';
import {default as linkedinIcon} from '../../img/linkedin-icon.svg';
import {default as vimeoIcon} from '../../img/vimeo-icon.svg';

export const Socials = () => {

  const socials = [
    { href: 'https://www.facebook.com/mamaadvertising/',
      src: facebookIcon, 
      alt: 'Facebook icon'
    },
    { href: 'https://www.instagram.com/mama__hq/',
      src: instagramIcon, 
      alt: 'Instagram icon'
    },
    { href: 'https://www.linkedin.com/company/mama-advertising/',
      src: linkedinIcon, 
      alt: 'Linkedin icon'
    },
    { href: 'https://vimeo.com/user20793305',
      src: vimeoIcon, 
      alt: 'Vimeo icon'
    },            
  ]

  const renderSocials = (socials ) => {
    return socials.map((social, index) => {
      return (
          <a
            key={index}
            href={social.href}
            target="_blank"
            rel="noopener noreferrer"

          >
            <img src={social.src} className="social-icon" alt="social.src" />
          </a>
      )
    })
  }


  return (
    <span className="socials">
      {renderSocials(socials) }                          
  </span>
  )
}
