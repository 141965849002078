import React from 'react'
import {NavLink} from 'react-router-dom'
import './Drawer.scss'
// import {Backdrop} from '../../UI/Backdrop/Backdrop'
import {Auxiliary} from '../../../hoc/Auxilary/Auxiliary'

export const Drawer = (props) => {

  const cls = ['Drawer']

  const links = []

  let isAuth = true


  if (isAuth) {
    links.push({to: '/', label: 'Home', exact: true})
    links.push({to: '/leads', label: 'Leads report', exact: false})
    links.push({to: '/analytics', label: 'Analytics report', exact: false})
    links.push({to: '/logout', label: 'Log out', exact: false})
  } else {
    links.push({to: '/login', label: 'Log in', exact: false})
  }



  if (!props.isOpen) {
    cls.push('closed')
  }

  const clickHandler = () => {
    props.onClose()
		document.body.classList.remove('fixed')
  }

  const renderLinks = (links) => {
    return links.map((link, index) => {
      return (
        <li key={index}>
          <NavLink
            to={link.to}
            exact={link.exact}
            activeClassName="active"
            // onClick={clickHandler}
          >
            {link.label}
          </NavLink>
        </li>
      )
    })
  }


  return (
    <Auxiliary>

      <nav className={cls.join(' ')} onClick={clickHandler}>
        <div className="container">
          <ul>
            { renderLinks(links) }
          </ul>
        </div>  
      </nav>
      {/* {props.isOpen ? <Backdrop onClick={clickHandler} /> : null } */}

    </Auxiliary>
  )
}