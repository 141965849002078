import {SET_FORMDATA} from '../types'

const handlers = {
  [SET_FORMDATA]: (state, {payload}) => ({...state, formData: payload}),      
  DEFAULT: state => state
}

export const leadsReducer = (state, action) => {
  const handler = handlers[action.type] || handlers.DEFAULT
  return handler(state, action)
}
