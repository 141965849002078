import React from 'react'
import './ChartSwitcher.scss'
import { Button } from '../../UI/Button/Button';
 
export const ChartSwitcher = (props) => {
 return (
    <div className="ChartSwithcer">
      <Button 
				disabled={props.measure === 'weekly' ? true : false}
				classname={props.measure === 'weekly' ? 'active' : ''} 
				onClick={props.onClickHandler} label="Weekly"/>
      <Button 
				disabled={props.measure === 'monthly' ? true : false}
				classname={props.measure === 'monthly' ? 'active' : ''} 
				onClick={props.onClickHandler} label="Monthly"/>   
    </div>
 )
}